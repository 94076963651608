// src/services/ApiService.js
import axios from 'axios';

// Create an axios instance
const api = axios.create({
  //baseURL: 'https://s3p3wesarjyjd6ab4epcfaj7ry0bdibn.lambda-url.eu-central-1.on.aws', // Replace with your actual API base URL
  baseURL: 'https://stage.storybox.cloud/colab'
});

// Interceptor to add the x-authenticated-for header
api.interceptors.request.use((config) => {
  const user = JSON.parse(localStorage.getItem('user')); // Replace this with the actual email, dynamically if needed.
  console.log('config', config)
  config.headers['x-authenticated-for'] = "mondello2@artech-is.com" //user.username;
  config.headers['x-company-for'] = user.companyId;

  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  return Promise.reject(error);
});

const ApiService = {

  getToken(){
    return api.get('')
  },
  // Fetch projects
  getProjects(limit, offset) {
    return api.get(`/projectsLite?limit=${limit}&offset=${offset}`);
  },

  // Fetch company projects
  getCompanyProjects(limit, offset, offsetId) {
    if(offsetId) return api.get(`/getCompanyProjectsLite?limit=${limit}&offsetId=${offsetId}`)
    return api.get(`/getCompanyProjectsLite?limit=${limit}&offset=${offset}`);
  },
  // Fetch project
  getProject(projectId) {
    return api.get(`/project/${projectId}`);
  },

  // Create project
  createProject(project) {
    return api.post('/project', project);
  },

  // Update project
  updateProject(projectId, project) {
    return api.patch(`/project/${projectId}`, project);
  },



  startTakeUpload(fileSize, name, orientation, videoResolution,  ownerEmail, projectId, sceneId, type = 'video', platform = 'web', tagsEn = []) {
    let config = {
      headers: {
          'X-API-DEVICE-ID': 'react-webapp',
          'X-API-TOKEN': JSON.parse(localStorage.getItem('user'))?.authenticationTokens?.accessToken
      }
  };
    config.headers.platform = platform;
    config.headers.videoResolution = videoResolution;
    config.headers.orientation = orientation;

    if(type === 'video') {
        return axios.post(`https://api.stage.storybox.blog/api/2.0/takes/upload/start?name=${encodeURIComponent(name)}&fileSize=${fileSize}&ownerEmail=${encodeURIComponent(ownerEmail)}&projectId=${projectId}&sceneId=${sceneId}`, tagsEn, config);
    } else {
        return axios.post(`https://api.stage.storybox.blog/api/1.2/takes/images/upload/start?name=${encodeURIComponent(name)}&fileSize=${fileSize}&ownerEmail=${encodeURIComponent(ownerEmail)}&projectId=${projectId}&sceneId=${sceneId}`, tagsEn, config);
    }
},
// TODO: rename to something more generic
uploadTakeToCloud(url, file, onUploadProgress, cancelToken){
    const options = {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
        onUploadProgress,
        cancelToken
    };

    return axios.put(url, file, options);
},
finishUploadTakeToCloud(eTags, syncId, type){
    let config = {
      headers: {
          'X-API-DEVICE-ID': 'react-webapp',
          'X-API-TOKEN': JSON.parse(localStorage.getItem('user'))?.authenticationTokens?.accessToken,
          'Content-Type': 'application/json'
      }
  };
    if(type === 'video') {
        return axios.post(`https://api.stage.storybox.blog/api/2.0/takes/upload/${syncId}/finish`, eTags, config);
    } else {
        return axios.post(`https://api.stage.storybox.blog/api/1.2/takes/images/upload/${syncId}/finish`, null, config);
    }
},
};

export default ApiService;