import React, { useEffect, useState, useMemo } from 'react';
import ApiService from '../services/ApiService';
import './ProjectList.css';
import { useNavigate } from 'react-router-dom';
import * as _ from 'lodash';
import { collection, onSnapshot, getDocs, addDoc, updateDoc, doc, deleteDoc, query, where, setDoc, limit, orderBy, startAfter } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig';
import { v4 as uuidv4 } from 'uuid'; // Use UUID for custom ID generation
 
const ProjectList = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newProjectTitle, setNewProjectTitle] = useState('');
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [filter, setFilter] = useState('myProjects'); // State to manage project type
  const [lastVisible, setLastVisible] = useState(null); // To store the last visible document

  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));
  const fbUser = JSON.parse(localStorage.getItem('fbUser'));

  const xApiToken = encodeURI(user?.authenticationTokens?.accessToken);
  const userEmail = user?.username;
  const companyId = user?.companyId;

  const fbUserMemo = useMemo(() => fbUser, [fbUser?.auth?.currentUser?.uid]);

  useEffect(() => {
    let unsubscribeProjects = () => { };


    // Only run if fbUser is defined, user is signed in, and not anonymous
    if (fbUserMemo?.auth?.currentUser && !fbUserMemo.auth.currentUser.isAnonymous) {
      console.log('effect fired', fbUserMemo);

      const projectsCollection = collection(db, 'projects');

      const fetchInitialProjects = async () => {
        try {
          // Fetch the initial data using getDocs()
          const queryResult = query(projectsCollection, where("permissions.usersOnProject", "array-contains", "mondello2@artech-is.com"/* fbUserMemo.auth.currentUser.email */),// You can change the order field as needed
          limit(5)); // Limit to 5 projects
          const querySnapshot = await getDocs(queryResult);
          /*const projectsData = querySnapshot.docs.map(doc => ({
            id: doc.id, // Document ID
            ...doc.data() // Document data
          })); */

          // Get the last document visible from this query for pagination
          const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      
          setLastVisible(lastVisibleDoc);
          //setProjects(projectsData);

          // Subscribe to real-time changes
          unsubscribeProjects = onSnapshot(queryResult, (snapshot) => {
           /*  const updatedProjectsData = snapshot.docs.map(doc => ({
              id: doc.id,
              title: doc.data().title,
              scenes: doc.data().scenes || [], // Ensure scenes is an array
              ...doc.data()
            }));
            console.log('Real-time projects update:', updatedProjectsData); // ovo vrati sve projekte iz query-a */
            snapshot.docChanges().forEach((change) => {
              //Ovdje vrati samo izmijenjene stvari
              if (change.type === "added") {
                // Handle newly added project
                const newProject = { id: change.doc.id, ...change.doc.data() };
                setProjects(prevProjects => _.uniqBy([...prevProjects, newProject], '_id'));
                console.log("Added project:", newProject);
              }
              
              if (change.type === "modified") {
                // Handle modified project
                const updatedProject = { id: change.doc.id, ...change.doc.data() };
                setProjects(prevProjects =>
                  _.uniqBy(prevProjects.map(project => project.id === change.doc.id ? updatedProject : project), '_id')
                );
                console.log("Modified project:", updatedProject);
              }
      
              if (change.type === "removed") {
                // Handle removed project
                const removedProjectId = change.doc.id;
                setProjects(prevProjects => _.uniqBy(prevProjects.filter(project => project.id !== removedProjectId), '_id'));
                console.log("Removed project with ID:", removedProjectId);
              }
            });
            //setProjects(updatedProjectsData);
          });
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      };

      fetchInitialProjects();
    }

    // Cleanup the listener on component unmount
    return () => {
      unsubscribeProjects();
    };
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      let data = [];
      if (filter === 'myProjects') {
        data = await ApiService.getProjects(10, projects.length);
      } else if (filter === 'companyProjects') {
        data = await ApiService.getCompanyProjects(10, projects.length, projects.length ? _.last(projects)?._id : null);
      }

      setProjects((prevProjects) => _.unionBy([...prevProjects, ...data], '_id'));
    } catch (error) {
      console.error('Error fetching projects:', error);
    } finally {
      setLoading(false);
    }
  };

  // Function to load more projects
const loadMoreProjects = async () => {
  if (!lastVisible) return;

  try {
    const projectsCollection = collection(db, "projects");

    // Query to fetch next 5 projects starting after the last visible document
    const queryResult = query(
      projectsCollection,
      where("permissions.usersOnProject", "array-contains", "mondello2@artech-is.com"),
      startAfter(lastVisible), // Start after the last document from the previous query
      limit(5) // Limit to 5 projects
    );

    const querySnapshot = await getDocs(queryResult);

    // Get the last visible document for the next pagination step
    const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

    const moreProjectsData = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    // Append the new projects to the existing ones
    setProjects((prevProjects) => _.uniqBy([...prevProjects, ...moreProjectsData], '_id'));
    setLastVisible(lastVisibleDoc);
    // If less than 5 projects are returned, it means there are no more projects to load
    if (querySnapshot.docs.length < 5) {
      setLastVisible(null); // No more projects to load
    }
  } catch (error) {
    console.error("Error loading more projects:", error);
  } finally {
  }
};
  const handleProjectClick = (projectId) => {
    navigate(`/projects/${projectId}`);
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const handleCreateProject = async (e) => {
    e.preventDefault();
    try {
      const projectId = uuidv4();
      const sceneDocRef = doc(db, 'projects', projectId);
      const newProject = {
        _id: projectId,
        _partition: `project=${projectId}`,
        title: newProjectTitle,
        createdAt: new Date(new Date().toISOString()),
        name: newProjectTitle,
        orientation: "LANDSCAPE",
        scenes: [],
        companyId: companyId,
        updatedAt: new Date(new Date().toISOString()),
        permissions: {
          _id: uuidv4(),
          _partition: `projectPermissions=project=${projectId}`,
          ownerId: "mondello2@artech-is.com",//userEmail,
          adminGroup: ["mondello2@artech-is.com"],
          usersOnProject: [userEmail, "mondello2@artech-is.com"]
        },
      };
      setDoc(sceneDocRef, newProject)
          .then(() => {
            console.log('New project created with custom ID: ', projectId);
          })
          .catch((error) => {
            console.error('Error creating new scene: ', error);
          });
      handleProjectClick(projectId);
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  const handleDeleteProject = async (e, projectId) => {
    e.preventDefault();
    e.stopPropagation();

    try {
      // Reference the specific project document in the 'projects' collection
      const projectDocRef = doc(db, 'projects', projectId);
  
      // Delete the document
      await deleteDoc(projectDocRef);
  
      setProjects((prevProjects)=> _.filter(prevProjects, p => p._id !== projectId))
      console.log(`Project with ID: ${projectId} has been deleted successfully.`);
    } catch (error) {
      console.error("Error deleting project:", error);
    }
  };
  return (
    <div className="projects-list">
      <header className="header">
        <div className="user-info">
          <span>Logged in as: {userEmail}</span>
          <button onClick={handleLogout} className="logout-btn">Logout</button>
        </div>
      </header>

            <h1>Projects List</h1>

      {/* <div className="filter-options">
        <button
          className={filter === 'myProjects' ? 'filter-btn active' : 'filter-btn'}
          onClick={() => {
            setProjects([]);
            setFilter('myProjects');
          }}
        >
          My Projects
        </button>
        <button
          className={filter === 'companyProjects' ? 'filter-btn active' : 'filter-btn'}
          onClick={() => {
            setProjects([]);
            setFilter('companyProjects');
          }}
        >
          Company Projects
        </button>
      </div> */}

      <div className="projects-wrapper">
        {projects?.map((project) => (
          <div
            className="project-item"
            onClick={() => handleProjectClick(project._id)}
            id={project._id}
            key={project._id}
            style={{ backgroundImage: `url(${`${project.thumbnail?.urlDetails?.url}?X-API-TOKEN=${xApiToken}`})` }}
          >
            <div className="project-overlay">
              <span className="project-title">{project.title}</span>
            </div>
            <button className='delete' onClick={(e) => handleDeleteProject(e, project._id)}>X</button>
          </div>
        ))}
      </div>

      {loading ? (
        <p className="loading">Loading...</p>
      ) : (
        lastVisible && <button className="load-more-btn" onClick={loadMoreProjects}>
          Load more
        </button>
      )}

      <button className="create-project-btn" onClick={() => setShowCreateForm(!showCreateForm)}>
        {showCreateForm ? 'Cancel' : 'Create New Project'}
      </button>

      {showCreateForm && (
        <form className="create-project-form" onSubmit={handleCreateProject}>
          <input
            type="text"
            placeholder="Project Title"
            value={newProjectTitle}
            onChange={(e) => setNewProjectTitle(e.target.value)}
            required
          />
          <button type="submit" className="submit-btn">
            Create Project
          </button>
        </form>
      )}
    </div>
  );
};

export default ProjectList;