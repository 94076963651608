// src/ProjectDetails.js
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebaseConfig'; // Adjust the path as needed
import { collection, onSnapshot, doc, updateDoc, getDoc, arrayRemove, arrayUnion, setDoc, deleteDoc } from 'firebase/firestore';
import ApiService from '../services/ApiService';
import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid'; // Use UUID for custom ID generation
import './ProjectDetail.css';
import axios from 'axios';
import { convertBytesToSize, processObject } from '../utils/utils';
import UploadButton from './UploadButton';
import SwitchCheckbox from './SwitchCheckbox';

const ProjectDetails = () => {
  const { projectId } = useParams(); // Get the projectId from the route
  const [project, setProject] = useState(null);
  const [scenes, setScenes] = useState([]);
  const [selectedScene, setSelectedScene] = useState(null);
  const [selectedTake, setSelectedTake] = useState(null);
  const [newSceneTitle, setNewSceneTitle] = useState('');
  const [updateSceneTitle, setUpdateSceneTitle] = useState('');
  const [newSceneDescription, setNewSceneDescription] = useState('');
  const [newProjectTitle, setNewProjectTitle] = useState(''); // State for project title
  const [updatedAt, setUpdatedAt] = useState(null);
  const [takes, setTakes] = useState([]);
  const [showUploadProgress, setShowUploadProgress] = useState(false);

  const xApiToken = encodeURI(JSON.parse(localStorage.getItem('user'))?.authenticationTokens?.accessToken);

  let projectRef;
  let scenesRef;
  const selectedSceneIDRef = useRef();
  useEffect(() => {
    projectRef = doc(db, `projects/${projectId}`);
    scenesRef = collection(db, 'projects', projectId, 'scenes'); // Pointing to the subcollection 'scenes'


    console.log('projectRef', projectRef)
    // Get project details
    const unsubscribeProject = onSnapshot(projectRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        console.log('project update detected', docSnapshot.data());

        setProject(docSnapshot.data());
      }
    });

    // Get scenes for the project
    const unsubscribeScenes = onSnapshot(scenesRef, (snapshot) => {
      const scenesData = snapshot.docs
        // .filter((doc) => doc.data().projectId === projectId) // Filter scenes by projectId
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
      console.log('scene update detected', snapshot, scenesData)
      setScenes(scenesData);
      if (selectedSceneIDRef.current) {
        const existingScene = _.find(scenesData, s => s._id === selectedSceneIDRef.current);
        //console.log('existingScene', existingScene)
        if (existingScene) setSelectedScene(existingScene);
      }
    });

    return () => {
      unsubscribeProject();
      unsubscribeScenes();
    };
  }, [projectId]);

  useEffect(() => {
    //getProject();
  }, [updatedAt]);

  useEffect(() => {
    selectedSceneIDRef.current = selectedScene?._id;
    if (selectedScene) getTakes(selectedScene.takes);
  }, [selectedScene]);

  const getTakes = async (sceneTakes) => {
    const takesPromises = _.map(
      _.filter(sceneTakes, (t) => !!t.urlDetails?.url && !t.deleted),
      (take) => {
        if (take.urlDetails.type === 'url') {
          return Promise.resolve({ fileUrl: `${take.urlDetails.url}?X-API-TOKEN=${xApiToken}`, id: take.id });
        } else {
          return axios
            .get(`${take.urlDetails.url}?X-API-TOKEN=${xApiToken}`)
            .then((res) => ({ data: res.data, id: take.id }));
        }
      }
    );
    const response = await Promise.allSettled(takesPromises);
    // Filter out only fulfilled promises and map the value or data
    const newTakes = _.map(
      _.filter(response, { status: 'fulfilled' }),
      (res) => res.value.data || res.value.fileUrl
    );

    //console.log('newTakes', newTakes);
    setTakes(_.filter(newTakes, (t) => !!t));
  };

  // Add a new scene
  const handleAddScene = async () => {
    if (newSceneTitle.trim()) {
      try {
        const sceneId = uuidv4();

        // Reference to the 'scenes' collection with the custom ID
        const sceneDocRef = doc(db, 'projects', projectId, 'scenes', sceneId);

        const newSceneData = {
          _id: sceneId,
          _partition: `project=${projectId}`,
          title: newSceneTitle,
          name: newSceneTitle,
          orderPosition: scenes.length,
          selectedTake: null,
          takes: [],
          createdAt: new Date(new Date().toISOString()),
          duration: 3,
          dynamicLength: false,
          logoEnabled: false,
          withSound: false,
          placeholderImage: "",
          placeholderImageUrl: {
            type: "",
            url: "",
          },
          placeholderVideo: "",
          placeholderVideoUrl: {
            type: "",
            url: "",
          },
          sceneDescription: "",
          gdprs: [],
          lut: {
            lutId: "",
            name: "",
            url: "",
          },
          virtualBackground: {
            id: "",
            name: "",
            virtualBackgroundId: "",
            mediaType: "",
            urlDetails: {
              type: "",
              url: "",
            },
          },
          transition: null,
          textOverlays: [],
          lowerThirds: [],
          bRoleScenes: [],
          bRoleOffset: 0,
          muteBackgroundMusic: false,
          projectId,
        };
        // Use setDoc to add the new scene with the custom ID
        setDoc(sceneDocRef, newSceneData)
          .then(() => {
            //console.log('New scene created with custom ID: ', sceneId);
            const projectDocRef = doc(db, 'projects', projectId);
            updateDoc(projectDocRef, {
              scenes: arrayUnion(sceneId)
            });
          })
          .catch((error) => {
            console.error('Error creating new scene: ', error);
          });
        setNewSceneTitle('');
      } catch (error) {
        console.error('Error adding scene:', error);
      }
    }
  };

  // Delete a scene
  const handleDeleteScene = async (event, sceneId) => {
    event.stopPropagation();
    try {
      const sceneDocRef = doc(db, 'projects', projectId, 'scenes', sceneId);

      // Delete the scene
      deleteDoc(sceneDocRef)
        .then(() => {
          //console.log('Scene successfully deleted!');
          const projectDocRef = doc(db, 'projects', projectId);
            updateDoc(projectDocRef, {
              scenes: arrayRemove(sceneId)
            });
        })
        .catch((error) => {
          console.error('Error deleting scene: ', error);
        });
      if (sceneId === selectedSceneIDRef.current)
        setSelectedScene(null);
    } catch (error) {
      console.error('Error deleting scene:', error);
    }
  };

  // Update scene
  const handleUpdateScene = async (property, value) => {
    if (selectedScene) {
      try {
        const sceneDocRef = doc(db, 'projects', projectId, 'scenes', selectedScene._id);
        updateDoc(sceneDocRef, {
          [property]: value
        });
        //console.log(`Scene[${property}] updated successfully!`);

        if(property === 'title')
          setUpdateSceneTitle('');
        if(property === 'sceneDescription')
          setNewSceneDescription('');
      } catch (error) {
        console.error('Error updating scene:', error);
      }
    }
  };

  // Add a new take
  const handleAddTake = async () => {
    try {
      const take = {
        contentType: 'video',
        createdAt: new Date(new Date().toISOString()),
        fileName: '38cac5ea-2c96-4349-ba82-ec85c6c5468e.mp4',
        id: uuidv4(),
        leftVideoTrim: 0,
        rightVideoTrim: 0,
        urlDetails: {
          url: 'https://api.stage.storybox.blog/api/1.2/takes/38cac5ea-2c96-4349-ba82-ec85c6c5468e',
        },
      };
      //const createdTake = await ApiService.createTake(selectedScene._id, take);
      //console.log('createdTake', createdTake);

      const sceneDocRef = doc(db, 'projects', projectId, 'scenes', selectedScene._id);

      // Step 1: Get the scene document
      const sceneDocSnapshot = await getDoc(sceneDocRef);

      if (sceneDocSnapshot.exists()) {
        // Step 4: Add the updated take
        await updateDoc(sceneDocRef, {
          takes: arrayUnion(take),
          selectedTake: take
        });

        //console.log('Take updated successfully');
      } else {
        console.log('Scene not found');
      }
    } catch (error) {
      console.error('Error adding take:', error);
    }
  };

  const onFileChange = async (inFile, inTakeName) => {
    const chunkSize = 1048576 * 5;
    const sType = inFile.type.split('/');
    const type = sType[0];
    const extension = type === 'video' ? 'mp4' : sType[1];
    const element = type === 'video' ? document.createElement('video') : document.createElement('img');
    element.src = URL.createObjectURL(inFile);

    await processObject(element, type);

    const elementWidth = type === 'video' ? element.videoWidth : element.naturalWidth;
    const elementHeight = type === 'video' ? element.videoHeight : element.naturalHeight;


    const ownerId = project.permissions?.ownerId;
    const startUploadResponse = await ApiService.startTakeUpload(inFile.size, `${inTakeName}.${extension}`, project.orientation, `${elementWidth}x${elementHeight}`, ownerId, project._id, selectedScene._id, type);
    const syncId = startUploadResponse.data.syncId;

    const eTags = [];
    setShowUploadProgress({ syncId, eTags, total: inFile?.size, loaded: 0 });
    const onUploadProgress = progressEvent => {
      setShowUploadProgress({ loaded: progressEvent.loaded, total: progressEvent.total, syncId, eTags })
    }
    if (startUploadResponse.data.presignedUrl) {
      const cloudUploadResponse = await ApiService.uploadTakeToCloud(startUploadResponse.data.presignedUrl, inFile, onUploadProgress);
      const eTag = cloudUploadResponse.headers.etag;
      eTags.push({ eTag: eTag, partNumber: 1 });
    } else {
      let i = 0;
      while (i < startUploadResponse.data.presignedUrls.length) {
        const uploadChunk = inFile.slice(i * chunkSize, (i + 1) * chunkSize);
        const cloudUploadResponse = await ApiService.uploadTakeToCloud(startUploadResponse.data.presignedUrls[i], uploadChunk, onUploadProgress);
        const eTag = cloudUploadResponse.headers.etag;
        eTags.push({ eTag: eTag, partNumber: i + 1 });
        i++;
      }
    }
    const finalResponse = await ApiService.finishUploadTakeToCloud(eTags, syncId, type);
    const url = finalResponse.data.value;

    const take = {
      id: syncId,
      fileName: `${uuidv4()}.${extension}`,
      createdAt: new Date(new Date().toISOString()),
      urlDetails: {
        type: 'object',
        url: url,
      },
      leftVideoTrim: 0,
      rightVideoTrim: 0,
      contentType: type,
    };

    const sceneDocRef = doc(db, 'projects', projectId, 'scenes', selectedScene._id);

    // Step 1: Get the scene document
    const sceneDocSnapshot = await getDoc(sceneDocRef);

    if (sceneDocSnapshot.exists()) {
      // Step 4: Add the updated take
      await updateDoc(sceneDocRef, {
        takes: arrayUnion(take),
        selectedTake: take
      });

      //console.log('Take updated successfully');
    } else {
      console.log('Scene not found');
    }
    setShowUploadProgress({})
  }
  // Delete a take
  const handleDeleteTake = async (event, sceneId, index, id) => {
    event.stopPropagation();

    const takeToUpdate = selectedScene.takes[index];
    const sceneDocRef = doc(db, 'projects', projectId, 'scenes', sceneId);

    // Step 1: Get the scene document
    const sceneDocSnapshot = await getDoc(sceneDocRef);

    if (sceneDocSnapshot.exists()) {
      const sceneData = sceneDocSnapshot.data();

      // Step 2: Find the current take in the `takes` array by its ID
      const currentTake = sceneData.takes.find(take => take.id === takeToUpdate.id);

      if (currentTake) {
        // Step 3: Remove the old take
        await updateDoc(sceneDocRef, {
          takes: arrayRemove(currentTake)
        });

        // Step 4: Add the updated take
        await updateDoc(sceneDocRef, {
          takes: arrayUnion({ ...takeToUpdate, deleted: true })
        });

        console.log('Take updated successfully');
      } else {
        console.log('Take not found in the scene');
      }
    } else {
      console.log('Scene not found');
    }
  };

  // Update project title
  const handleUpdateProjectTitle = async (e) => {
    if (newProjectTitle.trim() && project) {
      try {
        //const updatedProject = await ApiService.updateProject(projectId, { title: newProjectTitle });
        const projectRef = doc(db, 'projects', projectId);
        updateDoc(projectRef, {
          title: newProjectTitle,
          name: newProjectTitle
        });
        setNewProjectTitle('');
      } catch (error) {
        console.error('Error updating project title:', error);
      }
    }
  };

  const handleFlagChange = (flag, value) => {
    const sceneDocRef = doc(db, 'projects', projectId, 'scenes', selectedScene._id);
    try {
      updateDoc(sceneDocRef, {
        [flag]: value
      });
      //console.log('Task status updated successfully!');
    } catch (error) {
      console.error("Error updating task: ", error);
    }
  };

  const onTakeSelected = (id) => {
    const selectedTake = _.find(selectedScene.takes, t => t.id === id);
    handleUpdateScene('selectedTake', selectedTake)
  }

  return (
    <div className="project-details-container">
      {/* Header section with project title and update functionality */}
      <div className="project-header">
        <h1>{project?.title}</h1>
        <div className="update-project-title">
          <input
            type="text"
            value={newProjectTitle}
            onChange={(e) => setNewProjectTitle(e.target.value)}
            placeholder="Update Project Title"
            onBlur={handleUpdateProjectTitle}
          />
          {/*           <button onClick={handleUpdateProjectTitle}>Update Title</button>
 */}        </div>
      </div>

      <div className='project-body'>
        <div className="scenes-list">
          <div className="add-scene" style={{ marginBottom: 32 }}>
            <input
              type="text"
              value={newSceneTitle}
              onChange={(e) => setNewSceneTitle(e.target.value)}
              placeholder="New Scene Title"
            />
            <button className="add" onClick={handleAddScene}>
              Add Scene
            </button>
          </div>
          <ul>
            {_.orderBy(scenes, 'orderPosition').map((scene) => (
              <li
                key={scene.id}
                onClick={() => setSelectedScene(scene)}
                className={selectedScene?._id === scene._id ? 'selected' : ''}
              >
                <span className="scene-title">{scene.title}</span>
                <span className={`scene-order ${scene._id === project.opener || scene._id === project.closer ? 'vertical' : ''}`}>{scene._id === project.opener ? 'intro' : scene._id === project.closer ? 'outro' : scene.orderPosition + 1}</span>
                <button className="delete" onClick={(e) => handleDeleteScene(e, scene._id)}>
                  Delete
                </button>
              </li>
            ))}
          </ul>


        </div>

        {!!scenes?.length && <div className="scene-details">
          {selectedScene && (
            <div>
              <h3>{selectedScene.title}</h3>
              <p>{selectedScene.sceneDescription || 'No description available.'}</p>

              <div className="flags-container">
                <SwitchCheckbox
                  checked={!!selectedScene.withSound}
                  onChange={(value) => handleFlagChange('withSound', value)}
                  type={"toggle"}
                  label={"With sound"}
                  spaceBetweenLabelAndCheckmark={"between"}
                />
                <SwitchCheckbox
                  checked={!!selectedScene.logoEnabled}
                  onChange={(value) => handleFlagChange('logoEnabled', value)}
                  type={"toggle"}
                  label={"Logo enabled"}
                  spaceBetweenLabelAndCheckmark={"between"}
                />

              </div>

              <h4>Update Scene Title/Description</h4>
              <div className="update">
                <input
                  type="text"
                  value={updateSceneTitle}
                  onChange={(e) => setUpdateSceneTitle(e.target.value)}
                  placeholder="Update Title"
                  onBlur={() => handleUpdateScene('title', updateSceneTitle || selectedScene.title)}
                />
                <textarea
                  value={newSceneDescription}
                  onChange={(e) => setNewSceneDescription(e.target.value)}
                  placeholder="Update Description"
                  onBlur={() => handleUpdateScene('sceneDescription', newSceneDescription || selectedScene.sceneDescription)}
                />
                {/*               <button onClick={handleUpdateScene}>Update</button>
 */}            </div>

              <div className="takes-list">
                <h2>Takes</h2>
                <ul>
                  {takes &&
                    takes.map((take, index) => (
                      <li
                        key={take.id}
                        onClick={() => onTakeSelected(take.id)}
                        className={selectedScene?.selectedTake?.id === take.id || _.indexOf(_.filter(selectedScene.takes, (t) => !!t.urlDetails?.url && !t.deleted), selectedScene.selectedTake) === index ? 'selected' : ''}
                      >
                        {take?.thumbnail && <div className="take-thumbnail" style={{ backgroundImage: `url(${take?.thumbnail})` }}></div>}
                        {!take?.thumbnail && <video className='take-video' src={take} muted></video>}
                        <span className="take-name">{take.fileName}</span>
                        <button className="delete" onClick={(e) => handleDeleteTake(e, selectedScene._id, index, take?.id)}>
                          Delete
                        </button>
                      </li>
                    ))}
                </ul>
                <div className="add-take">
                 {/*  <button className="add" onClick={handleAddTake}>
                    Add New Take
                  </button> */}
                  <span className="upload-btn" ><UploadButton onChange={onFileChange} fileName={uuidv4()} accept={'image/*, video/*'} /></span>
                </div>

              </div>
            </div>
          )}
          {!selectedScene && <p>Select a scene to see details.</p>}
        </div>}
      </div>

      {(showUploadProgress?.total || showUploadProgress.multiple) && <div className="file-upload-progress">
        <div className="TakeUploadModal__Header">
          <div className={'TakeUploadModal__Header--title'}>
            {'Uploading media'}
          </div>
        </div>

        <div className="sb-modal-content-wrapper">
          {!showUploadProgress.multiple && <div className="progress-wrapper">
            <progress id="items-loader" value={showUploadProgress?.loaded} max={showUploadProgress?.total}></progress>
            <div className="file-lenght-wrapper">
              <span className='loaded'>{convertBytesToSize(showUploadProgress?.loaded)}</span>
              <span className='total'>{convertBytesToSize(showUploadProgress?.total)}</span>
            </div>

          </div>}
          {showUploadProgress.multiple && _.map(Object.keys(showUploadProgress.multiple), key => {
            const uploadProgress = showUploadProgress.multiple[key];
            return (<div className="progress-wrapper">
              <span className='title'>{_.toLower(key)}</span>
              <progress id="items-loader" value={uploadProgress?.loaded} max={uploadProgress?.total}></progress>
              <div className="file-lenght-wrapper">
                <span className='loaded'>{convertBytesToSize(uploadProgress?.loaded)}</span>
                <span className='total'>{convertBytesToSize(uploadProgress?.total)}</span>
              </div>
            </div>)
          })}
          <div className="buttons-wrapper">
          </div>
        </div>
      </div>}
    </div>
  );
};

export default ProjectDetails;