import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, signInWithCustomToken, signInWithRedirect } from 'firebase/auth';
import ApiService from './services/ApiService';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyByO8lFRndkvvHe3NSIvFFnVHFttcOxp4U",
  authDomain: "storybox-staging-full-firebase.firebaseapp.com",
  projectId: "storybox-staging-full-firebase",
  storageBucket: "storybox-staging-full-firebase.appspot.com",
  messagingSenderId: "299070740202",
  appId: "1:299070740202:web:2a0927f596943dee922993"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Auth state change listener
auth.onAuthStateChanged((user) => {
  if (user) {
    console.log('User is signed in:', user);
    localStorage.setItem('fbUser', JSON.stringify({ ...user, isAuthenticated: true }));
  } else {
    console.log('No user signed in');
  }
});


const signInWithCustomTokenAsync = async () => {
  const startTime = performance.now(); // Start timing

  try {
    const { token } = await ApiService.getToken();
    if(token)
      await signInWithCustomToken(auth, token);
    const endTime = performance.now(); // End timing
    const duration = endTime - startTime; // Calculate duration
    console.log(`Sign-in successful. Time taken: ${duration.toFixed(2)} milliseconds`);
  } catch (error) {
    console.error("Error during sign-in:", error);
    const endTime = performance.now(); // End timing
    const duration = endTime - startTime; // Calculate duration
    console.log(`Sign-in failed. Time taken: ${duration.toFixed(2)} milliseconds`);

  }
};

// Call the sign-in function
signInWithCustomTokenAsync(/* hardcodedToken */);

// Export the Firestore and Auth instances
export { db, auth };