import React, { useState} from 'react';
import _ from 'lodash';

const UploadButton = (props) => {
    const { onChange, accept} = props;

    return (
    <div className="outline-upload-container">
            <label>
                {'Click to upload file'}
                <input
                    id="file-upload-button"
                    type="file"
                    accept={accept}
                    style={{ display: 'none' }}
                    onChange={(action) => {
                        console.log('onChange file', action)
                        action.preventDefault();
                        let file = action.target.files[0];
                        if (!file) {
                            onChange(null);
                            return;
                        }
                        onChange(file, file.name)
                    }}
                />
            </label>
        </div>
    )
}

export default UploadButton;

