import React, { useEffect, useState } from "react";
import './SwitchCheckbox.css';

/* INSTRUCTIONS FOR USE */
//Switch type between "toggle" or "classic" to change UI. Example --> type={"classic"}
//Label left or right side of the check box, put labelPosition "label-right" for right label position, default is left. Example --> labelPosition={"label-right"}
//Space between checkmark and label, put spaceBetweenLabelAndCheckmark for space between "between". Example --> spaceBetweenLabelAndCheckmark={"between"}

/* <SwitchCheckbox
    checked={props.isApprovalConfigured}
    onChange={handleCheckboxChange}
    disabled={!authenticationService.hasRole('ROLE_SUPER_ADMIN')}
    type={"classic"}
    label={"All approvers are required to give a feedback"}        --- OPTIONAL
    labelPosition={"label-right"}                                  --- OPTIONAL
    spaceBetweenLabelAndCheckmark={"between"}                      --- OPTIONAL
/> */

const SwitchCheckbox = (props) => {
    const [isChecked, setIsChecked] = useState(!!props.checked);

    const handleCheckboxChange = () => {
        const newValue = !isChecked;
        setIsChecked(newValue);
        if (props.onChange) {
            props.onChange(newValue);
        }
    };

    useEffect(()=>{
        setIsChecked(!!props.checked)
    },[props.checked])

    return (
        <div className={`checkbox-toggle ${props.disabled ? "disabled" : ""} ${props.labelPosition === "label-right" ? "label-right" : ""} ${props.spaceBetweenLabelAndCheckmark === "between" ? "between" : ""}`}>
            {<span className="checkbox-label">{props.label}</span>}
            <label className="checkbox-toggle-label">
                <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    disabled={props.disabled}
                />
                <span className={`checkmark ${props.type} ${isChecked ? 'checked' : ''}`}></span>
            </label>
        </div>
    );
}

export default SwitchCheckbox;