// src/services/ApiService.js
import axios from 'axios';

// Create an axios instance
const api = axios.create({
  baseURL: 'https://api.stage.storybox.blog/api/1.2', // Replace with your actual API base URL
});

// Interceptor to add the x-authenticated-for header
/* api.interceptors.request.use((config) => {
  const userEmail = 'alma.rahic@storybox.cloud'; // Replace this with the actual email, dynamically if needed.
  config.headers['x-authenticated-for'] = userEmail;
  return config;
}, (error) => {
  return Promise.reject(error);
}); */

api.interceptors.response.use((response) => {
  return response.data;
}, (error) => {
  return Promise.reject(error);
});

const AuthService = {
  login: async (credentials) => {
    const requestConfig = {
      headers: { 'X-API-DEVICE-ID': 'react-webapp', 'Content-Type': 'application/json', },
      auth: credentials,
  };
    return api.post(`/auth`, null, requestConfig);
  },

};

export default AuthService;