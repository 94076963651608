export const processObject = (object, type, src='', useTimeout= false, timeout=1000, callback=null) => {
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'image':
            case 'img':
                object.onload = () => {
                    if (callback) {
                        callback(src, type, 'resolved')
                    }
                    resolve(object);
                }
                object.onerror = (err) => {
                    if (callback) {
                    callback(src, type, 'rejected')
                    }
                    //reject(err);
                }
                break;
            case 'video':
                // debugger;
                object.onloadeddata = () => {
                    resolve(object);
                }
                object.onerror = (err) => {
                    if (callback) {
                    callback(src, type, 'rejected')
                    }
                    //reject();
                }

                object.onloadedmetadata = (e) => {
                    if (callback) {
                        callback(src, type, 'onloadedmetadata');
                        resolve(object);
                    }
                }
                break;
        }

        if (useTimeout) {
            setTimeout(() => {
                const message = `Load time for element type '${type}', took longer than ${timeout / 1000} seconds`
                //console.log(message)
                if (callback) {
                    callback(src, type, message, true)
                }
                reject(message)
            }, timeout);
        }

    });
}

export    const convertBytesToSize = (bytes) =>{
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return '0'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}